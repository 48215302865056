import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        "div",
        [
          _vm.posts.length > 0
            ? _c(
                VContainer,
                [
                  _c(
                    VRow,
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        VCol,
                        { attrs: { cols: "12", md: "12", jus: "" } },
                        _vm._l(_vm.posts, function (post, i) {
                          return _c(
                            "div",
                            { key: i },
                            [
                              i > 0 ? _c(VDivider) : _vm._e(),
                              _c(
                                VCard,
                                { staticClass: "mb-7" },
                                [
                                  _c(
                                    VCardTitle,
                                    [
                                      _c(
                                        VAvatar,
                                        {
                                          staticClass: "profileImage",
                                          attrs: { tile: "" },
                                        },
                                        [
                                          post.CompanyPost.Logo != null
                                            ? _c(VImg, {
                                                staticStyle: {
                                                  cursor: "pointer",
                                                },
                                                attrs: {
                                                  src: post.CompanyPost.Logo,
                                                },
                                              })
                                            : _c(VImg, {
                                                staticStyle: {
                                                  cursor: "pointer",
                                                },
                                                attrs: {
                                                  src: require("../../../assets/profile.png"),
                                                },
                                              }),
                                        ],
                                        1
                                      ),
                                      _c("h2", { staticClass: "h2" }, [
                                        _vm._v(_vm._s(post.CompanyPost.Name)),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCardSubtitle,
                                    { staticClass: "feedSubtitle mt-4" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.get_date_string(post.Date)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    VCardText,
                                    { staticClass: "mt-4" },
                                    [
                                      _c("span", {
                                        staticClass: "feedText",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.get_post_content(post)
                                          ),
                                        },
                                      }),
                                      _c(
                                        VCardActions,
                                        { staticClass: "ma-1" },
                                        [
                                          _c(VSpacer),
                                          post.Type != 0
                                            ? _c(
                                                VBtn,
                                                {
                                                  attrs: {
                                                    color: "secondary",
                                                    dark: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.feed_details(
                                                        post
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  post.Type == 1
                                                    ? _c(
                                                        VIcon,
                                                        { attrs: { left: "" } },
                                                        [_vm._v("mdi-file")]
                                                      )
                                                    : _vm._e(),
                                                  post.Type == 2
                                                    ? _c(
                                                        VIcon,
                                                        { attrs: { left: "" } },
                                                        [_vm._v("mdi-calendar")]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("feed_check")
                                                      ) +
                                                      " "
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "h4",
                { staticClass: "h4 mx-auto", staticStyle: { width: "30%" } },
                [_vm._v(_vm._s(_vm.$t("feed_empty")))]
              ),
        ],
        1
      )
    : _c(
        VContainer,
        [
          _c(VProgressCircular, {
            staticStyle: { "margin-left": "50%" },
            attrs: { indeterminate: "", size: "70", color: "primary" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }